/**
 * 
 * Disable button on click
 * 
 */

$(function() {
    initDisableButtonOnClick();
});

function initDisableButtonOnClick() 
{
    if (document.querySelector('.js-button-click-disable')) {
        $('.js-button-click-disable').on('click', function(e) {
            let btn = $(this);
            // disabled button
            btn.prop('disabled', true);
            // get closest form
            let form = btn.closest('form');
            // submit form
            if(form) {
                form.submit();
            }
        });
    }
}